import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import AppImage from '../../common/assets/image/footerapp.svg';
import PlaystoreImage from '../../common/assets/image/footerplay.svg';
import { __ } from '../../common/utils/LanguageHelper';
import { IconButton } from '@material-ui/core';
// import EnergizeLogo from '../../common/assets/image/Energize_logo.png';

import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import { Link } from 'gatsby-plugin-intl';

const Footer = ({ row, colOne, colTwo, titleStyle }) => {
    const socialMediaList = [
        {
            icon: <TwitterIcon />,
            url: 'https://twitter.com/awasul1',
        },
        {
            icon: <InstagramIcon />,
            url: 'https://www.instagram.com/awasul1',
        },
        {
            icon: <LinkedInIcon />,
            url: 'https://www.linkedin.com/company/awasul1/',
        },
        {
            icon: <LanguageIcon />,
            url: 'http://awasul1.com/',
        },
    ];

    return (
        <FooterWrapper id="footerSection">
            <Container noGutter mobileGutter width="1200px">
                <Box className="row" {...row}>
                    <Box {...colOne}>
                        <Heading
                            content={__('حمل تطبيقنا الآن', 'Download The App')}
                            {...titleStyle}
                        />
                        <Box className="imageWrapper">
                            <a href="#">
                                <Image src={AppImage} alt="App store Image" />
                            </a>

                            <a href="#">
                                <Image
                                    src={PlaystoreImage}
                                    alt="Play store Image"
                                />
                            </a>
                        </Box>
                    </Box>
                    <Box {...colTwo}>
                        <Heading
                            content={__(
                                'تواصل معنا عبر حسابتنا',
                                'Download The App'
                            )}
                            {...titleStyle}
                        />
                        <List>
                            {socialMediaList.map(({ icon, url }, i) => (
                                <ListItem key={i}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={url}
                                    >
                                        <IconButton className="socialIcon">
                                            {icon}
                                        </IconButton>
                                    </a>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>

                <Box className="row copyRight" {...row}>
                    <Link to="/usage-and-privacy-policy">
                        <Text
                            content={__(
                                'سياسة الاستخدام والخصوصية',
                                'Usage and Privacy Policy'
                            )}
                            className="copyRightText"
                            color="#FFFFFF"
                        />
                    </Link>
                    <Link to="/terms-and-conditions">
                        <Text
                            content={__(
                                'الشروط والأحكام',
                                'Terms and Conditions'
                            )}
                            className="copyRightText"
                            color="#FFFFFF"
                        />
                    </Link>
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            content={__(
                                'جميع الحقوق محفوظة',
                                'All Rights Reserved'
                            )}
                            color="#ffffff7a"
                            ml="10px"
                            mb="0"
                        />
                        {/* <Image
                            src={EnergizeLogo}
                            alt="Energize Logo"
                            style={{
                                maxWidth: 100,
                            }}
                        /> */}
                    </span>
                </Box>
            </Container>
        </FooterWrapper>
    );
};

// Footer style props
Footer.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    colOne: PropTypes.object,
    colTwo: PropTypes.object,
    titleStyle: PropTypes.object,
    textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
    // Footer row default style
    row: {
        flexBox: true,
        margin: '0 auto',
        justifyContent: ['flex-start', 'flex-start', 'flex-start', 'center'],
        flexWrap: 'wrap',
    },
    // Footer col one style
    colOne: {
        width: ['100%', '30%', 'auto', 'auto'],
        marginLeft: '70px',
        mb: ['30px', 0],
        pl: ['0px', 0],
        pr: ['0px', '0px', 0],
    },
    // Footer col two style
    colTwo: {
        width: ['100%', '30%', 'auto', 'auto'],
        flexWrap: 'wrap',
    },
    // widget title default style
    titleStyle: {
        color: '#FFFFFF',
        fontSize: ['15px', '16px', '16px', '18px', '20px'],
        fontWeight: '600',
        lineHeight: '1.34',
        mb: ['15px', '18px', '18px', '20px', '30px'],
        fontFamily: 'Poppins',
    },
    // Default logo size
    logoStyle: {
        width: '128px',
        mb: '15px',
    },
    // widget text default style
    textStyle: {
        color: '#FFFFFF',
        fontSize: '16px',
        mb: '12px',
        fontWeight: '600',
    },
};

export default Footer;

import styled from 'styled-components';

const FooterWrapper = styled.section`
    padding: 50px 0;
    margin-top: 150px;
    background-repeat: no-repeat;
    background-position: 100% center;
    border-top: 1px solid #707070;
    overflow: hidden;
    background-color: #212141;
    z-index: 1;
    @media (max-width: 1440px) {
        padding: 80px 0 60px;
    }

    .socialIcon {
        color: #fff;
        background-color: #373758;
        margin: 6px;
        transition: all 0.3s ease;
        &:hover {
            background-color: #5c6edc;
        }
    }

    .imageWrapper {
        display: flex;
        @media (max-width: 1200px) {
            flex-direction: column;
        }
        img {
            margin-right: 15px;
            @media (max-width: 1200px) {
                margin-bottom: 15px;
                margin-right: 0;
                width: 150px;
            }
        }
    }
    .copyRight {
        margin-top: 120px;
        margin-left: 0;
        margin-right: 0;
        @media (max-width: 1440px) {
            margin-top: 80px;
        }
        @media (max-width: 768px) {
            width: calc(100% - 20px);
            margin-top: 60px;
        }
        @media (max-width: 600px) {
            margin-top: 20px;
        }
        .copyRightText {
            font-size: 16px;
            font-weight: 400;
            margin-left: 20px;
            margin-bottom: 0;
            @media (max-width: 480px) {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }
`;

const List = styled.ul`
    display: flex;
`;

const ListItem = styled.li`
    margin-left: 15px;
`;

export { List, ListItem };

export default FooterWrapper;
